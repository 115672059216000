import type { FC } from 'react';
import { useRouter } from 'next/router';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {Alert, Box, Button, Divider, FormHelperText, TextField} from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import CodeConfirm from "./code-confirm/code-confirm";
import CodeResend from "./code-resend";

interface Props {
  onSubmit: (phone: string) => void
  isCode: boolean
}

export const JWTLogin = ({ onSubmit, isCode }: Props) => {
  const isMounted = useMounted();
  const router = useRouter();
  const { login } = useAuth() as any;
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      submit: null
    },
    validationSchema: Yup.object({
      phoneNumber: Yup
        .string()
        .max(15)
        .matches(/^((\+7|7|8)+([0-9]){10})$/, 'Некорректный номер')
        .required('Номер не введен'),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        onSubmit(formik.values.phoneNumber)

        if (isMounted()) {
          // const returnUrl = (router.query.returnUrl as string) || '/dashboard';
          // router.push(returnUrl);
        }
      } catch (err) {
        console.error(err);

        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <TextField
          // variant={'standard'}
        error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
        fullWidth
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        label="Введите номер телефона"
        placeholder={'+7(999) 999-9999'}
        margin="normal"
        name="phoneNumber"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="text"
        value={formik.values.phoneNumber}
        disabled={isCode}
      />
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>
            {formik.errors.submit}
          </FormHelperText>
        </Box>
      )}

      {
        !isCode &&
        <Box sx={{ mt: 2 }}>
          <Button
              disabled={formik.isSubmitting || !formik.values.phoneNumber}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              // onClick={() => onSubmit(formik.values.phoneNumber)}
          >
            Продолжить
          </Button>
        </Box>
      }
    </form>
  );
};
