import React, {useEffect, useMemo, useRef, useState} from "react";
import {Button, TextField} from "@mui/material";
// @ts-ignore
import styles from './code-confirm.module.scss'

const COUNT_DIGITS = 4

interface Props {
    onSubmit: (code: string) => void
}

const CodeConfirm = ({ onSubmit }: Props) => {
    const [digits, setDigits] = useState<string[]>([])
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const refs = []

    const isValid = useMemo(() => digits.every(d => !isNaN(parseInt(d))), [digits])
    const stringCode = useMemo(() => digits.join(''), [digits])

    useEffect(() => {
        setDigits([...Array(COUNT_DIGITS)])
        // refs[0].focus()
    }, [])

    useEffect(() => {
        if (isValid) {
            onSubmit(stringCode)
        }
    }, [isValid])

    const setDigit = (digit: string, index: number) => {
        setDigits(prev => prev.map((d, i) => {
            if (i !== index) return d
            else return digit
        }))
    }

    const onChange = (val: string) => {
        if (!val) {
            setDigit(val.replace(/\D/,''), currentIndex)
            if (currentIndex === 0) return
            return refs[currentIndex-1].focus()
        }

        const digit = val.replace(/\D/, '')

        setDigit(digit, currentIndex)
        if (!digit || currentIndex === digits.length - 1) return
        refs[currentIndex+1].focus()
    }

    return (
        <div className={styles.confirm}>
            <b>Введите код подтверждения</b>
            <div className={styles.code}>
                {
                    digits.map((d, i) => (
                        <div className={styles.digitInput} key={i}>
                            <TextField
                                inputRef={instance => refs[i] = instance}
                                focused={i === currentIndex}
                                placeholder={'0'}
                                type={'text'}
                                onFocus={() => setCurrentIndex(i)}
                                value={digits[i]}
                                onChange={e => onChange(e.target.value)}
                                InputProps={{ inputProps: { min: 0, max: 9, maxLength: 1, pattern: '[0-9]' }, autoFocus: i === 0 }}
                            />
                        </div>
                    ))
                }
            </div>
            <Button
                style={{ marginTop: '1em' }}
                disabled={!isValid}
                fullWidth
                size="large"
                variant="contained"
                onClick={() => onSubmit(stringCode)}
            >
                Вход
            </Button>
        </div>
    )
}

export default CodeConfirm
