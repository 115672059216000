import React from "react";
import './style.module.scss'

const LoginIcons = ({ children }) => {
    return (
        <div  style={{ display: 'flex' }}>
            { children }
        </div>
    )
}

export default LoginIcons