// @ts-ignore
import styles from './style.module.scss'
import React from "react";
import {IconType} from "react-icons";
import {useTheme} from "@mui/system";

interface Props {
    Icon: IconType
    isActive: boolean
    onClick: () => void
}

export const TypeIcon = ({ Icon, isActive, onClick }: Props) => {

    return (
        <div className={styles.icon} onClick={onClick} style={{ border: !isActive && 'none' }}>
            <Icon
                size={35}
                color={!isActive ? '#D1D5DB' : '#1F64E5'}
            />
        </div>
    )
}