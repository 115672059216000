import React, {useEffect, useMemo, useState} from "react";
import {Link} from "@mui/material";
// @ts-ignore
import styles from './style.module.scss'

interface Props {
    onClickLink: () => void
}

const CodeResend = ({ onClickLink }: Props) => {
    const [seconds, setSeconds] = useState(15)

    const isActive = useMemo(() => seconds > 0, [seconds])

    useEffect(() => {
        const interval = setInterval(() => setSeconds(prev => prev - 1), 1000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className={styles.resend}>
            <p onClick={() => {
                !isActive && onClickLink()
            }} style={{ color: isActive && '#9FA4AC' }}>Отправить код еще раз</p>
            { isActive && <span>00:{ String('00').slice(seconds.toString().length, 2) + seconds }</span> }
        </div>
    )
}

export default CodeResend