import React, {useState} from "react";
import {NextPage} from "next";
import {useAppContext} from "@app/app-context";
import {GuestGuard} from "@components/authentication/guest-guard";
import Head from "next/head";
import {JWTLogin} from "@components/authentication/jwt-login";
import {Alert, Box, Card, Container, Divider, Typography} from "@mui/material";
import {RiFunctionLine, RiSettings2Line} from "react-icons/ri";
import CodeConfirm from "../../components/authentication/code-confirm";
import CodeResend from "../../components/authentication/code-resend";
import {TypeIcon} from "@components/login/types/TypeIcon";
import LoginIcons from "../../components/login/types/LoginIcons";
import {ErrorApi} from "@app/types/errorApi";
import {useAuth} from "../../hooks/use-auth";
import {useRouter} from "next/router";
import {formatPhone} from "../../utils/format-phone";
import {Roles} from "@app/types/user";

const LoginPage: NextPage = () => {
    const {api} = useAppContext()
    const [phone, setPhone] = useState<string>()
    const [code, setCode] = useState<string>()
    const [error, setError] = useState<string>()
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [sendSuccess, setSendSuccess] = useState<boolean>(false)
    const auth = useAuth()
    const router = useRouter()

    const sendCode = (p: string) => {
        if (!p) return
        setPhone(p)

        api.auth.getCode({ phone: formatPhone(p) })
            .then(res => {
                setSendSuccess(res)
            })
            .catch((err: ErrorApi) => {
                setError(err.response.data.error_message)
            })
    }

    const login = (c: string) => {
        if (!c || c.length !== 4) return
        setCode(c)

        api.auth.login({ phone: phone, code: c })
            .then(res => {
                auth.login(res.token, isAdmin ? Roles.ADMIN : Roles.MANAGER)
                    .then(() => router.push(router.query.returnUrl as string || '/'))
            })
            .catch((err) => {
                setError(err.response.data.error_message)
            })
    }

    return (
        <>
            <Head>
                <title>
                    Авторизация
                </title>
            </Head>
            <Box
                component="main"
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        py: {
                            xs: '60px',
                            md: '120px'
                        }
                    }}
                >
                    { error && <Alert severity="warning" style={{ marginBottom: '2em', backgroundColor: '#F3F4F6', color: '#6B7280' }}>{error}</Alert> }
                    <Card
                        elevation={16}
                        sx={{p: 4}}
                    >
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <LoginIcons>
                                <TypeIcon Icon={RiFunctionLine} isActive={!isAdmin} onClick={() => setIsAdmin(false)} />
                                <TypeIcon Icon={RiSettings2Line} isActive={isAdmin} onClick={() => setIsAdmin(true)} />
                            </LoginIcons>
                            <Typography variant="h4">
                                Авторизация
                            </Typography>
                            <Typography
                                color="textSecondary"
                                sx={{mt: 2}}
                                variant="body2"
                            >
                                {isAdmin ? 'Админ-панель' : 'Контент менеджер'}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                mt: 3
                            }}
                        >
                            <JWTLogin onSubmit={sendCode} isCode={sendSuccess}/>
                            { sendSuccess && <CodeConfirm onSubmit={login} /> }
                            <Divider sx={{ my: 3 }} />
                            {
                                code &&
                                <CodeResend
                                    onClickLink={() => {
                                        setCode('')
                                        sendCode(phone)
                                    }}
                                />
                            }
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    )
}

LoginPage.getLayout = (page) => (
    <GuestGuard>
        {page}
    </GuestGuard>
)

export default LoginPage
